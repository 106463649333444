import React from "react";
import "./ImageMaxWidth.scss";

interface ImageMaxWidthProps {
  imageSrc: string;
  imageAlt: string;
  maxWidth: number;
  className?: string;
  customStyles?: object;
}

export const ImageMaxWidth = ({
  imageSrc,
  imageAlt,
  maxWidth,
  className,
  customStyles,
}: ImageMaxWidthProps) => (
  <div className={`blog-image-container ${className}`}>
    <img
      className="blog-image-container__image"
      alt={imageAlt}
      src={imageSrc}
      style={{ ...customStyles, maxWidth: maxWidth }}
    />
  </div>
);
