import React from "react";
import "./PostIntro.scss";

interface Props {
  title?: string;
  description: any;
  imageUrl: string;
}

export function PostIntro({ title, description, imageUrl }: Props) {
  return (
    <div className={"post-intro"}>
      <div className={"left"} style={{ background: `url(${imageUrl})` }} />
      <div className={"right"}>
        {title && <h2>{title}</h2>}
        {description}
      </div>
    </div>
  );
}
