import React from "react";
import Logo from "../../../assets/logo-white.svg";
import LogoInverse from "../../../assets/logo-inverse.svg";
import { Link } from "gatsby";

export const NavigationLeft = ({
  isMenuOpen,
  handleLogoClick,
  handleDotPosition,
  navItems,
}) => (
  <div className="left" onClick={(e) => handleLogoClick()}>
    {isMenuOpen ? (
      <span className="logo-inverse">
        <LogoInverse />
      </span>
    ) : (
      <Link
        onClick={() => handleDotPosition(navItems[0].dotPosition)}
        to="/"
        className="logo"
        aria-label={"logo"}
      >
          <Logo />
      </Link>
    )}
  </div>
);
