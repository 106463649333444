import "./ActivableButton.scss";

import React from "react";

interface ButtonWhiteProps {
  text: string;
  [x: string]: any;
}

const ButtonWhite = ({ text }: ButtonWhiteProps) => {
  return (
    <button type="submit" className="activable-button active">
      {text}
    </button>
  );
};

export default ButtonWhite;
