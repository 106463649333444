import React from "react";
import { AnimatedDot } from "../../widgets/AnimatedDot";
import { MenuItem } from "./MenuItem";
import { NavItem } from "../Navigation.types";

export const NavigationCenter = ({
  navItems,
  dotHoverEffect,
  handleDotPosition,
  dot,
  isEntre,
}) => (
  <div className="center">
    {!isEntre && (
      <>
        <div className="menu">
          {navItems.map((navItem: NavItem, index: number) => {
            const { name, section, dotPosition } = navItem;
            return (
              <MenuItem
                key={`navItem-${index}`}
                name={name}
                id={navItem.id}
                section={section}
                onMouseEnter={(): void => dotHoverEffect(true)}
                onMouseLeave={(): void => dotHoverEffect(false)}
                onSetActive={(): void => {
                  handleDotPosition(dotPosition);
                }}
              />
            );
          })}
        </div>
        <AnimatedDot {...dot} />
      </>
    )}
  </div>
);
