import "./Contact.scss";

import MarekProfile from "../../assets/Marek-profile.png";
import PiotrProfile from "../../assets/Piotr-profile.png";
import React from "react";
import { Header } from "../Header/Header";
import { OurPartners } from "../OurPartners/OurPartners";

export const getEmail = (e) => {
  location.href = `mailto:${e.target.id}@allbright.io`;
};

export const Contact = (props: ContactProps) => (
  <div className="contact">
    <div className="container">
      <div className="container-items">
        <Header title="Contact us" subTitle="Starting a project?" />

        <div className="address">
          AllBright Technologies Sp. z o.o., ul. Krzysztofa Kieślowskiego 3D/2,
          02-962 Warsaw Poland
        </div>
        <div className="emailAdress" id="hello" onClick={getEmail}>
          oi.thgirblla@olleh
        </div>
        <div className="owners">
          <figure className="ownerFigure">
            <a href="https://www.linkedin.com/in/gocal/">
              <div className="octagonWrap">
                <div className="octagon">
                  <img src={MarekProfile} alt="AllBright.io CTO picture" />
                </div>
              </div>
            </a>

            <div className="ownerProfile">
              <span className="ownerName">Marek Gocał</span>
              <span className="ownerTitle">CTO</span>
              <a
                href="https://www.linkedin.com/in/gocal/"
                className="linkedInLink"
                target="_blank"
              >
                LINKEDIN PROFILE
              </a>
              <div className="emailAdressOwner" id="marek" onClick={getEmail}>
                oi.thgirblla@keram
              </div>
            </div>
          </figure>
          <figure className="ownerFigure">
            <a href="https://www.linkedin.com/in/piotrgasierkiewicz/">
              <div className="octagonWrap">
                <div className="octagon">
                  <img src={PiotrProfile} alt="AllBright.io COO  picture" />
                </div>
              </div>
            </a>
            <div className="ownerProfile">
              <span className="ownerName">Piotr Gąsierkiewicz</span>
              <span className="ownerTitle">COO</span>
              <a
                href="https://www.linkedin.com/in/piotrgasierkiewicz/"
                className="linkedInLink"
                target="_blank"
              >
                LINKEDIN PROFILE
              </a>
              <div className="emailAdressOwner" id="piotr" onClick={getEmail}>
                oi.thgirblla@rtoip
              </div>
            </div>
          </figure>
        </div>
      </div>
    </div>
  </div>
);
