import "./Layout.scss";
import React, { useEffect, useState } from "react";
import { Footer, FooterAlt } from "../components/Footer/Footer";
import Navigation from "../components/Navigation/Navigation";
import CookiesBanner from "../components/widgets/CookiesBanner/CookiesBanner";

interface Props {
  pageContext: { layout: string };
  children: JSX.Element;
}

export default function Layout(props: Props) {
  const { children } = props;
  const { location } =
    typeof window !== "undefined" ? window : { location: { pathname: "" } };
  const { pathname } = location;
  const [isScrolled, setScrolled] = useState(false);
  const [isFooterAlt, setIsFooterAlt] = useState(false);

  const listenToScroll = () => {
    if (window.pageYOffset > 0) {
      setScrolled(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);

    return () => {
      window.removeEventListener("scroll", listenToScroll);
    };
  }, []);

  useEffect(() => {
    if (pathname.includes("/case-study/fintech-connector")) {
      setIsFooterAlt(true);
    }
  }, [pathname]);

  const onPopUpVisibility = (isVisible: boolean) => {
    // setDisplayForm(true);
    const body = document.querySelector("body");
    if (body != null) {
      if (isVisible) {
        body.classList.add("no-scroll");
      } else {
        body.classList.remove("no-scroll");
      }
    }
  };

  return (
    <div className="App">
      <Navigation
        onPopUpVisibility={onPopUpVisibility}
        pathname={pathname}
        isEntre={props.pageContext.layout === "entre"}
      />
      <div>
        <script
          type="text/javascript"
          src="src/compontents/Klaro/config.js"
        ></script>
        {children}
      </div>
      {isFooterAlt ? <FooterAlt /> : <Footer />}
    </div>
  );
}
