import { BlogTable } from "../../../../src/components/Blog/postElements/BlogTable";
import { BannerMobileApp } from "../../../../src/components/Blog/postElements/BannerMobile";
import { PostIntro } from "../../../../src/components/Blog/postElements/PostIntro";
import { ColumnContent } from "../../../../src/components/Blog/postElements/ColumnContent";
import { ImageMaxWidth } from "../../../../src/components/Blog/postElements/ImageMaxWidth";
import { FullWidthImage } from "../../../../src/components/Blog/postElements/FullWidthImage";
import { Quote } from "../../../../src/components/Blog/postElements/Quote";
import * as React from 'react';
export default {
  BlogTable,
  BannerMobileApp,
  PostIntro,
  ColumnContent,
  ImageMaxWidth,
  FullWidthImage,
  Quote,
  React
};