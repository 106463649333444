// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-blog-blog-post-content-blog-post-content-tsx": () => import("./../../../src/components/Blog/BlogPostContent/BlogPostContent.tsx" /* webpackChunkName: "component---src-components-blog-blog-post-content-blog-post-content-tsx" */),
  "component---src-content-blog-allbright-five-payment-solutions-part-1-index-mdx": () => import("./../../../src/content/blog/allbright-five-payment-solutions-part1/index.mdx" /* webpackChunkName: "component---src-content-blog-allbright-five-payment-solutions-part-1-index-mdx" */),
  "component---src-content-blog-allbright-five-payment-solutions-part-2-index-mdx": () => import("./../../../src/content/blog/allbright-five-payment-solutions-part2/index.mdx" /* webpackChunkName: "component---src-content-blog-allbright-five-payment-solutions-part-2-index-mdx" */),
  "component---src-content-blog-allbright-five-payment-solutions-part-3-index-mdx": () => import("./../../../src/content/blog/allbright-five-payment-solutions-part3/index.mdx" /* webpackChunkName: "component---src-content-blog-allbright-five-payment-solutions-part-3-index-mdx" */),
  "component---src-content-blog-how-do-i-integrate-braintree-payments-into-my-flutter-mobile-app-index-mdx": () => import("./../../../src/content/blog/how-do-i-integrate-braintree-payments-into-my-flutter-mobile-app/index.mdx" /* webpackChunkName: "component---src-content-blog-how-do-i-integrate-braintree-payments-into-my-flutter-mobile-app-index-mdx" */),
  "component---src-content-blog-webrtc-agora-integration-index-mdx": () => import("./../../../src/content/blog/webrtc/Agora-integration/index.mdx" /* webpackChunkName: "component---src-content-blog-webrtc-agora-integration-index-mdx" */),
  "component---src-content-blog-webrtc-mobile-sdk-agora-vs-quickblox-vs-twilio-index-mdx": () => import("./../../../src/content/blog/webrtc-mobile-sdk/Agora-vs-Quickblox-vs-Twilio/index.mdx" /* webpackChunkName: "component---src-content-blog-webrtc-mobile-sdk-agora-vs-quickblox-vs-twilio-index-mdx" */),
  "component---src-content-blog-webrtc-part-2-how-does-it-work-index-mdx": () => import("./../../../src/content/blog/webrtc-part-2-how-does-it-work/index.mdx" /* webpackChunkName: "component---src-content-blog-webrtc-part-2-how-does-it-work-index-mdx" */),
  "component---src-content-blog-what-is-the-future-of-mobile-wallets-index-mdx": () => import("./../../../src/content/blog/what-is-the-future-of-mobile-wallets/index.mdx" /* webpackChunkName: "component---src-content-blog-what-is-the-future-of-mobile-wallets-index-mdx" */),
  "component---src-content-blog-what-is-webrtc-part-1-benefits-index-mdx": () => import("./../../../src/content/blog/what-is-webrtc-part-1-benefits/index.mdx" /* webpackChunkName: "component---src-content-blog-what-is-webrtc-part-1-benefits-index-mdx" */),
  "component---src-content-blog-whats-best-in-2021-ionic-react-native-or-flutter-index-mdx": () => import("./../../../src/content/blog/what’s-best-in-2021-ionic-react-native-or-flutter/index.mdx" /* webpackChunkName: "component---src-content-blog-whats-best-in-2021-ionic-react-native-or-flutter-index-mdx" */),
  "component---src-content-blog-why-is-flutter-so-effective-and-how-can-it-help-my-business-index-mdx": () => import("./../../../src/content/blog/why-is-flutter-so-effective-and-how-can-it-help-my-business/index.mdx" /* webpackChunkName: "component---src-content-blog-why-is-flutter-so-effective-and-how-can-it-help-my-business-index-mdx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-how-we-work-tsx": () => import("./../../../src/pages/how-we-work.tsx" /* webpackChunkName: "component---src-pages-how-we-work-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-partners-entre-tsx": () => import("./../../../src/pages/partners/entre.tsx" /* webpackChunkName: "component---src-pages-partners-entre-tsx" */),
  "component---src-pages-portfolio-case-study-captio-tsx": () => import("./../../../src/pages/portfolio/case-study/captio.tsx" /* webpackChunkName: "component---src-pages-portfolio-case-study-captio-tsx" */),
  "component---src-pages-portfolio-case-study-fintech-connector-tsx": () => import("./../../../src/pages/portfolio/case-study/fintech-connector.tsx" /* webpackChunkName: "component---src-pages-portfolio-case-study-fintech-connector-tsx" */),
  "component---src-pages-portfolio-case-study-kardiolog-tsx": () => import("./../../../src/pages/portfolio/case-study/kardiolog.tsx" /* webpackChunkName: "component---src-pages-portfolio-case-study-kardiolog-tsx" */),
  "component---src-pages-portfolio-case-study-medrefer-tsx": () => import("./../../../src/pages/portfolio/case-study/medrefer.tsx" /* webpackChunkName: "component---src-pages-portfolio-case-study-medrefer-tsx" */),
  "component---src-pages-portfolio-index-tsx": () => import("./../../../src/pages/portfolio/index.tsx" /* webpackChunkName: "component---src-pages-portfolio-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-services-flutter-app-development-index-tsx": () => import("./../../../src/pages/services/flutter-app-development/index.tsx" /* webpackChunkName: "component---src-pages-services-flutter-app-development-index-tsx" */),
  "component---src-pages-services-health-wellbeing-medical-app-development-company-index-tsx": () => import("./../../../src/pages/services/health-wellbeing-medical-app-development-company/index.tsx" /* webpackChunkName: "component---src-pages-services-health-wellbeing-medical-app-development-company-index-tsx" */),
  "component---src-pages-services-mobile-development-index-tsx": () => import("./../../../src/pages/services/mobile-development/index.tsx" /* webpackChunkName: "component---src-pages-services-mobile-development-index-tsx" */)
}

