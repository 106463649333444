import React from "react";
import { variantsHamburgerMenu } from "../Navigation.consts";
import { NavItem } from "../Navigation.types";
import { MobileMenuItem } from "./MobileMenuItem";
import { motion } from "framer-motion";

export const MobileMenu = ({
  isMenuOpen,
  navItems,
  handleMenuClose,
  handleSimpleFormToggle,
  isEntre,
  handleEstimateToggle,
}) => (
  <div
    className={`hamburger-menu ${isMenuOpen ? "hamburger-menu-expanded" : ""}`}
  >
    <motion.div
      animate={isMenuOpen ? "open" : "closed"}
      variants={variantsHamburgerMenu}
      className="container"
    >
      {navItems.map((navItem: NavItem, index: number) => {
        const { section, name } = navItem;
        return (
          <MobileMenuItem
            key={`hamburgerItem-${index}`}
            name={name}
            section={section}
            onClick={handleMenuClose}
          />
        );
      })}
      {isEntre ? (
        <button
          onClick={() => {
            handleMenuClose();
            handleSimpleFormToggle();
          }}
        >
          REDEEM YOUR COUPON
        </button>
      ) : (
        <button
          onClick={() => {
            handleMenuClose();
            handleEstimateToggle();
          }}
        >
          Quick Estimate
        </button>
      )}
    </motion.div>
  </div>
);
