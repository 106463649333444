import { Link } from "gatsby";
import React from "react";

export const MobileMenuItem = ({
  name,
  section,
  onClick,
}: {
  name: string;
  section: string;
  onClick(): void;
}) => (
  <Link to={`/${section}`}>
    <div onClick={(e) => onClick()} className="item">
      {name}
    </div>
  </Link>
);
