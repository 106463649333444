import React from "react";
import { motion } from "framer-motion";
import { variantsFieldName } from "./utils";

interface AnimatedFieldProps {
  children: JSX.Element;
  isOpen: boolean;
}

const AnimatedField = (props: AnimatedFieldProps): JSX.Element => {
  return (
    <motion.div
      animate={props.isOpen ? "open" : "closed"}
      variants={variantsFieldName}
    >
      {props.children}
    </motion.div>
  );
};

export default AnimatedField;
