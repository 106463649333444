import { FootNote } from "../../../../src/components/Blog/postElements/FootNote";
import { FootNotes } from "../../../../src/components/Blog/postElements/FootNotes";
import { PostIntro } from "../../../../src/components/Blog/postElements/PostIntro";
import { FullWidthImage } from "../../../../src/components/Blog/postElements/FullWidthImage";
import * as React from 'react';
export default {
  FootNote,
  FootNotes,
  PostIntro,
  FullWidthImage,
  React
};