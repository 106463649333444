export const variantsContainer = {
    open: { stiffness: 1000, opacity: 1, y: 0, velocity: -1000 },
    closed: { stiffness: 1000, opacity: 1, y: "-8%", velocity: 0 },
  };
  
export const variantsFieldName = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -10 },
        delay: 0.3,
      },
    },
    closed: {
      y: 10,
      opacity: 0,
      transition: {
        y: { stiffness: 1000, velocity: -10 },
        delay: 0,
      },
    },
};

export const encode = (data: any) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export const ErrorMessages = {
  fileSize: "Maximum file size is 3mb.",
  atLeastOne: "Please select at least one.",
  budgetInfo: "Please provide buget info.",
  name: "Please provide a valid name",
  projectDescription: "Please provide project description.",
  amount: "Please provideamount.",
  company: "Please provide a valid company",
  subject:"Please provide a valid subject",
};