import "./Footer.scss";
import "../Klaro/klaro.scss";
import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import SocialIcon from "../../assets/social-icon.svg";
import LinkedInIcon from "../../assets/Linkedin-icon.svg";
import DribbleIcon from "../../assets/dribble.svg";
import { getEmail } from "../Contact/Contact";
import * as klaro from "../Klaro/klaro";
import { klaroConfig } from "../Klaro/config.js";

export function Footer() {
  if (typeof window !== "undefined") {
    window.onload = async function() {
      window.klaro = klaro;
      window.klaroConfig = klaroConfig;
      klaro.setup(klaroConfig);
      var dc = document.cookie;
      if (dc == "") {
        klaro.show(klaroConfig, false);
      }
    };
  }

  const handleOnClick = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    klaro.setup(klaroConfig);
    await klaro.show(klaroConfig, false);
  };

  return (
    <footer>
      <Link className="footer__link footer__link--left" to="/privacy-policy">
        Copyright by AllBright.io
      </Link>

      <div className="socialIcons"></div>

      <div className="footer__link footer__link--left">
        <span id="klaroo" onClick={handleOnClick}>
          Cookie Settings
        </span>
      </div>

      <div className="socialIcons">
        <a
          href="https://www.linkedin.com/company/allbright-technologies/about/"
          className="socialIcon"
          target="_blank"
        >
          <LinkedInIcon style={{ height: 24 }} />
        </a>
        <a
          href="https://clutch.co/profile/allbrightio"
          className="socialIcon"
          target="_blank"
        >
          <SocialIcon />
        </a>
        <a
          href="https://dribbble.com/AllBright_io"
          className="socialIcon"
          target="_blank"
        >
          <DribbleIcon />
        </a>
      </div>
      <div className="footer__link footer__link--right">
        <span id="hello" onClick={getEmail}>
          oi.thgirblla@olleh
        </span>
      </div>
    </footer>
  );
}

export function FooterAlt() {
  return (
    <footer className="footer-alt">
      <div className="footer-alt__container">
        <p className="footer-alt__slogan footer-alt__slogan--bold">
          Do you like the way we tackled this project?
        </p>
        <p className="footer-alt__slogan">
          Reach out to learn how we can tackle your project.
        </p>
        <div className="footer-alt__cta">
          <a href="mailto:hello@allbright.io">
            <span>contact us</span>
            <span> hello@allbright.io</span>
          </a>
        </div>
        <div className="footer-alt__link-box">
          <Link
            className="footer__link footer__link--left"
            to="/privacy-policy"
          >
            Copyright by AllBright.io
          </Link>
          <div className="socialIcons">
            <a
              href="https://www.linkedin.com/company/allbright-technologies/about/"
              className="socialIcon"
              target="_blank"
            >
              <LinkedInIcon style={{ height: 24 }} />
            </a>
            <a
              href="https://www.linkedin.com/company/allbright-technologies/about/"
              className="socialIcon"
              target="_blank"
            >
              <SocialIcon />
            </a>
            <a
              href="https://dribbble.com/AllBright_io"
              className="socialIcon"
              target="_blank"
            >
              <DribbleIcon />
            </a>
          </div>
          <a
            className="footer__link footer__link--right"
            href="mailto:hello@allbright.io"
          >
            hello@allbright.io
          </a>
        </div>
      </div>
    </footer>
  );
}
