import { navItems } from "./Navigation.consts";
import { useReducer, useEffect, useState } from "react";
import {
  reducer,
  initialState,
  SET_STICKY_NAV,
  SET_TRANSPARENT,
  TOGGLE_ESTIMATE_OPEN,
  SET_IS_MENU_OPEN,
  TOGGLE_SIMPLE_FORM_OPEN,
  SET_PREV_SCROLL,
  SET_DOT,
} from "./Navigation.state";
import { NavItem } from "./Navigation.types";
import * as Scroll from "react-scroll";
import { globalHistory } from "@reach/router";

export const useNavigation = (path, onPopUpVisibility, withDot) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [scrollTop, setScrollTop] = useState(0);
  const [isLandingNavScrollVisible, setIsLandingNavScrollVisible] = useState(
    false
  );

  const isAltColors =
    typeof window !== "undefined" &&
    (window.location.pathname.includes("/case-study/fintech-connector") ||
      window.location.pathname.includes("/services/"));

  useEffect(() => {
    return globalHistory.listen(() => {
      const navItem = state.navItems.find((navItem) => {
        if (navItem.section !== "") {
          return window.location.pathname.includes(
            navItem.section.replace("/", "")
          );
        }
      });

      if (window.location.pathname === "/") {
        handleDotPosition(state.navItems[0].dotPosition);
      } else {
        handleDotPosition(navItem.dotPosition);
      }
    });
  }, []);

  const listenToScroll = () => {
    if (window?.pageYOffset > 1) {
      dispatch({ type: SET_TRANSPARENT, payload: false });
    } else {
      dispatch({ type: SET_TRANSPARENT, payload: true });
    }
    const { prevScrollPosition } = state;
    const currentScrollPos = window.pageYOffset;
    setScrollTop(currentScrollPos);

    const visible =
      currentScrollPos <= 1 ? true : prevScrollPosition > currentScrollPos;
    dispatch({
      type: SET_PREV_SCROLL,
      payload: { prevScrollPosition: currentScrollPos, visible },
    });
  };

  const handleBurgerToggle = (isOpen: boolean) => {
    dispatch({ type: SET_STICKY_NAV, payload: true });
    const open = !state.isMenuOpen;
    onPopUpVisibility(open);
    dispatch({ type: SET_IS_MENU_OPEN, payload: open });
  };

  const handleEstimateToggle = (): void => {
    const open = !state.isEstimateOpen;
    onPopUpVisibility(open);
    dispatch({ type: TOGGLE_ESTIMATE_OPEN });
  };

  const handleSimpleFormToggle = (): void => {
    let open = !state.isSimpleFormOpen;
    onPopUpVisibility(open);
    dispatch({ type: TOGGLE_SIMPLE_FORM_OPEN });
  };

  const handleMenuClose = (): void => {
    onPopUpVisibility(false);
    dispatch({ type: SET_IS_MENU_OPEN, payload: false });
  };

  const handleLogoClick = (): void => {
    Scroll.animateScroll.scrollToTop({});
  };

  const handleDotPosition = (dotPosition: number) => {
    const { dot } = state;
    dot.isVisible = true;
    dot.position = dotPosition;
    dispatch({ type: SET_DOT, payload: dot });
  };

  const dotHoverEffect = (isEffect: boolean): void => {
    const { dot } = state;
    dot.isEffect = isEffect;
    dispatch({ type: SET_DOT, payload: dot });
  };

  const handleDotPositionOnUrlChange = (href?: string): void => {
    href = href ? href : path;
    if (href.includes("how-we-work")) {
      handleDotPosition(state.navItems[1].dotPosition);
    } else if (href.includes("portfolio")) {
      handleDotPosition(state.navItems[2].dotPosition);
    } else if (href.includes("blog")) {
      handleDotPosition(state.navItems[3].dotPosition);
    } else if (href.includes("contact")) {
      handleDotPosition(state.navItems[4].dotPosition);
    }
  };

  useEffect(() => {
    typeof window !== "undefined" &&
      window.addEventListener("scroll", listenToScroll);
    return () => {
      window.removeEventListener("scroll", listenToScroll);
    };
  }, [scrollTop]);

  useEffect(() => {
    if (typeof document !== "undefined" && withDot) {
      let sum = 0;
      state.navItems.forEach((x: NavItem) => {
        let navItem = document.getElementById(x.id);
        x.dotPosition = Math.floor((navItem.clientWidth + 40) / 2) + sum - 3;
        sum += navItem.clientWidth + 40;
      });
    }
    handleDotPositionOnUrlChange(path);

    const isHealthLanding =
      typeof window !== "undefined" &&
      window.location.pathname.includes("services/health");

    if (isHealthLanding) dispatch({ type: SET_STICKY_NAV, payload: false });
  }, [path]);

  return {
    state,
    isAltColors,
    listenToScroll,
    handleBurgerToggle,
    handleEstimateToggle,
    handleSimpleFormToggle,
    handleMenuClose,
    handleLogoClick,
    handleDotPosition,
    dotHoverEffect,
  };
};
