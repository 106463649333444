import React  from 'react'
import "./Hint.scss"

interface Props {
    content: string;
}

export function Hint(props : Props) {
    return (
        <>
            {props.content}
        </>
    )
}
