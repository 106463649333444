import React, { ReactElement } from 'react'
import "./BlogVideo.scss"
interface Props {
    video: string;

}

export function BlogVideo({ video }: Props): ReactElement {
    return (
        <div className="blog-video">
            <video id='video' controls={true} preload='metadata' >
                <source id='mp4' src={`${video}#t=0.01`} type='video/mp4' />
                <p>Your user agent does not support the HTML5 Video element.</p>
            </video>
        </div >
    )
}
