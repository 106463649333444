import React from "react";
import "./BannerMobile.scss";

interface Props {
  title?: string;
  description: string;
  style: any;
}

export function BannerMobileApp({ title, description, style }: Props) {
  return (
    <div className="banner-container banner-container--inverted" style={style}>
      <div className="cta-icon icon-mobile"></div>
      <div className="banner-container__texts banner-container__texts">
        {title && (
          <h3 className="banner-header banner-header--inverted">{title}</h3>
        )}
        <p className="banner-subheader banner-subheader--inverted">
          {description}
        </p>
      </div>
      <button
        className="button-red"
        onClick={() => {
          const formButton = document.getElementById("entre-coupon-form");
          if (formButton) {
            formButton.click();
          }
        }}
      >
        CONTACT
      </button>
    </div>
  );
}

export function BannerMobileWallet({ title, description }: Props) {
  return (
    <div className="banner-container">
      <div className="cta-icon icon-wallet" />
      <div className="banner-container__texts">
        {title && <h3 className="banner-header">{title}</h3>}
        <p className="banner-subheader">{description}</p>
      </div>
      <button
        className="button-red"
        onClick={() => {
          const formButton = document.getElementById("entre-coupon-form");
          if (formButton) {
            formButton.click();
          }
        }}
      >
        CONTACT
      </button>
    </div>
  );
}
