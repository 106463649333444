import React from "react";
import { SimpleForm } from "../../forms/SimpleForm";
import { QuickEstimate } from "../../forms/QuickEstimate";
import XShape from "../../../assets/X-Shape.svg";

export const NavigationForms = ({
  isSimpleFormBackground,
  handleSimpleFormToggle,
  isSimpleFormOpen,
  isEstimateBackground,
  handleEstimateToggle,
  isEstimateOpen,
}) => (
  <>
    <div
      className={`entree ${
        isSimpleFormBackground ? "entree-expand" : "entree-close"
      }`}
    >
      <div className="content">
        <div
          className="fab-row"
          id="entre-coupon-form"
          onClick={handleSimpleFormToggle}
        >
          <div className="fab">
            <XShape alt="" />
          </div>
        </div>
        <SimpleForm
          isOpen={isSimpleFormOpen}
          onClose={handleSimpleFormToggle}
        />
      </div>
    </div>
    <div
      className={`estimate ${
        isEstimateBackground ? "estimate-expand" : "estimate-close"
      }`}
    >
      <div className="content">
        <div
          id="quick-estimate"
          className="fab-row"
          onClick={handleEstimateToggle}
        >
          <div className="fab">
            <XShape alt="" />
          </div>
        </div>
        <QuickEstimate isOpen={isEstimateOpen} onClose={handleEstimateToggle} />
      </div>
    </div>
  </>
);
