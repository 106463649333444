import React from "react";
import "./Quote.scss";

interface Props {
  content: string;
  author: Element;
}

export function Quote({ content, author }: Props) {
  return (
    <div className={"quote"}>
      {content}
      {author}
    </div>
  );
}
