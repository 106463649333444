import React from "react";
import "./FullWidthImage.scss";

interface FullWidthImageProps {
  imageSrc: string;
  imageAlt: string;
  className?: string;
  customStyle?: object;
}

export const FullWidthImage = ({
  imageSrc,
  imageAlt,
  className,
  customStyle,
}: FullWidthImageProps) => (
  <div className={`full-width-image-container ${className}`}>
    <img
      className="full-width-image"
      alt={imageAlt}
      src={imageSrc}
      style={customStyle}
    />
  </div>
);
