import "./NewsletterBanner.scss";

import { Field, Form, InjectedFormikProps, withFormik } from "formik";

import { ButtonWhite } from "../widgets/ActivableButton";
import React from "react";

interface FormProps {
  title?: string;
  subtitle?: string;
}

interface FormValues {
  contact: string;
  agree: boolean;
}

const InnerForm: React.FC<InjectedFormikProps<
  FormProps,
  FormValues
>> = props => {
  const { errors, touched, handleSubmit } = props;
  return (
    <div className="newsletter-banner-container">
      <h2>
        {props.title ? props.title : "Follow business and technology trends"}
      </h2>
      <p>
        {props.subtitle ? props.subtitle : "Sign up to Allbright Newsletter"}
      </p>
      <Form
      // onSubmit={(e: { preventDefault: () => void }) => {
      //   e.preventDefault();
      //   handleSubmit();
      // }}
      >
        <div className="contact-row">
          <Field
            name="contact"
            validate={(value: string) => {
              const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
              return value != null && value.match(mailFormat)
                ? null
                : "Please provide contact.";
            }}
            placeholder="Enter your email"
          />
          {errors.contact && touched.contact && (
            <div className="error">{errors.contact}</div>
          )}
        </div>
        <ButtonWhite text="Submit" />
      </Form>
    </div>
  );
};

const NewsletterBanner = withFormik<FormProps, FormValues>({
  handleSubmit: (values: FormValues) => {
    console.log("Newsletter submit");
  }
})(InnerForm);

export default NewsletterBanner;
