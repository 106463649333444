import React from "react";
import classNames from "classnames";
import "./Navigation.styled.scss";
import { NavigationLeft } from "./NavigationLeft";
import { Hamburger } from "./Hamburger";
import { RouteComponentProps } from "react-router";
import ProgressBar from "../widgets/ProgressBar";
import { useNavigation } from "./Navigation.hooks";
import { NavigationCenter } from "./NavigationCenter";
import { NavigationRight } from "./NavigationRight";
import { MobileMenu } from "./MobileMenu";
import { NavigationForms } from "./NavigationForms";

interface NavigationProps extends RouteComponentProps {
  onPopUpVisibility(visible: boolean): void;
  pathname: string;
  isEntre: boolean;
}

export const Navigation = ({
  onPopUpVisibility,
  pathname,
  isEntre,
}: NavigationProps) => {
  const withDot = !isEntre;
  const {
    state,
    handleBurgerToggle,
    handleEstimateToggle,
    handleSimpleFormToggle,
    handleMenuClose,
    handleLogoClick,
    handleDotPosition,
    dotHoverEffect,
    isAltColors,
  } = useNavigation(pathname, onPopUpVisibility, withDot);

  const {
    isMenuOpen,
    navItems,
    stickyNav,
    dot,
    isEstimateBackground,
    isSimpleFormBackground,
    isEstimateOpen,
    isSimpleFormOpen,
    visible,
    transparentBg,
  } = state;

  return (
    <div
      className={isAltColors ? "nav-wrapper nav-wrapper--alt" : "nav-wrapper"}
    >
      <nav
        className={classNames("navigation", {
          fixed: stickyNav,
          navbarHidden: !visible,
        })}
        style={{
          background: transparentBg ? "transparent" : "",
          transition: "0.3s",
        }}
      >
        <div className="container">
          <NavigationLeft
            isMenuOpen={isMenuOpen}
            handleLogoClick={handleLogoClick}
            handleDotPosition={handleDotPosition}
            navItems={navItems}
          />
          <NavigationCenter
            navItems={navItems}
            dotHoverEffect={dotHoverEffect}
            handleDotPosition={handleDotPosition}
            dot={dot}
            isEntre={isEntre}
          />
          <NavigationRight
            isEntre={isEntre}
            handleEstimateToggle={handleEstimateToggle}
            handleSimpleFormToggle={handleSimpleFormToggle}
          />
          <Hamburger onToggle={handleBurgerToggle} isOpen={isMenuOpen} />
        </div>
        <div
          className={`circle ${isMenuOpen ? "circle-expand" : "circle-hidden"}`}
        />

        <MobileMenu
          isMenuOpen={isMenuOpen}
          navItems={navItems}
          handleMenuClose={handleMenuClose}
          handleSimpleFormToggle={handleSimpleFormToggle}
          isEntre={isEntre}
          handleEstimateToggle={handleEstimateToggle}
        />

        <NavigationForms
          isSimpleFormBackground={isSimpleFormBackground}
          handleSimpleFormToggle={handleSimpleFormToggle}
          isSimpleFormOpen={isSimpleFormOpen}
          isEstimateBackground={isEstimateBackground}
          handleEstimateToggle={handleEstimateToggle}
          isEstimateOpen={isEstimateOpen}
        />
        {!isMenuOpen && <ProgressBar path={pathname} />}
      </nav>
    </div>
  );
};

export default Navigation;
