import React  from 'react'
import "./BlogTable.scss"

function markdownTableToJson(data: string, type: string){
    let result = [];
    let dataSplit = data.split("|").filter(x => x !== "").map(x => x.trim());
    let rows = dataSplit.join(",").split(",,").map(x => x.split(","));

    rows.splice(2, rows.length).forEach(row => {
        let rowObject = {};
        row.forEach((value, i) => {
            rowObject[rows[0][i]] = value;
        })

        result.push(rowObject);
    });

    return result;
}

export function BlogTable(props) {
    let jsonData = markdownTableToJson(props.children, props.type);
    return (
        <div className="blog-table-container">
            <div className="blog-table">
                <div className="blog-table-cell">
                    <table className={`${props.type == 'text' ? 'type-text' : 'type-mixed'} ${props.customClass ? props.customClass : ''}`}>
                        <thead>
                            <tr>
                                {Object.keys(jsonData[0]).map(key => {
                                    key = key.replace("\\n", '<br/>');
                                    return (<th key={key} dangerouslySetInnerHTML={{__html: key}} />);
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {jsonData.map((array, index) => <tr>{Object.keys(array).map((key, i) => {
                                let td;
                                if(jsonData.length - 1 == index && i == 0){
                                    td = <td dangerouslySetInnerHTML={{ __html: `${array[key]}`}} id={`td-${index}-${i}`}/>;
                                }else{
                                    if(props.boldNumbers){
                                        let r = /\d+/g;
                                        let m = r.exec(array[key]);
                                        if(m !== null){
                                            array[key] = array[key].replace(m[0], `<b>${m[0]}</b>`);
                                        }
                                    }

                                    td = <td className={`${jsonData.length - 1 == index && props.isGreyLastRow ? 'grey-bg' : ''}`} dangerouslySetInnerHTML={{ __html: `<span class='hint' ${props.hints ? '' : 'style="display: none"'}>${props.hints && i == 0 ? props.hints[index] : ""}</span>${array[key]}`}} id={`td-${index}-${i}`}/>;
                                }

                                if(array[key].substr(0, 3) == "**_") {
                                    td = <td dangerouslySetInnerHTML={{ __html: `<span class='hint'>${props.hints ? props.hints[index] : ""}</span><strong><em>${array[key].replace('**_', '').replace('_**', '')}</em></strong>`}}/>
                                } else if(array[key].substr(0, 1) == "[") {
                                    let value = array[key].split("](")[0];
                                    let href = array[key].split("](")[1];
                                    td = <td dangerouslySetInnerHTML={{ __html: `<span class='hint'>${props.hints ? props.hints[index] : ""}</span><a href="${href.replace(')', '')}" target="_blank">${value.replace('[', '')}</a>`}}/>
                                } else if(array[key].split("](").length > 1) {
                                    let left = array[key].split("](")[0];
                                    let right = array[key].split("](")[1];
                                    let value = left.split("[")[1];
                                    let href = right.split(")")[0];
                                    left = left.split("[")[0];
                                    right = right.split(")")[1];
                                    td = <td dangerouslySetInnerHTML={{ __html:`<span class='hint'>${props.hints ? props.hints[index] : ""}</span> ${left} <a href=${href} target="_blank">${value}</a> ${right}`}}/>
                                }

                                return td;
                            })}</tr>)}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
