import { Link } from "gatsby";
import React from "react";

interface MenuItemProps {
  name: string;
  id: string;
  section: string;
  onSetActive: any;
  onMouseEnter: any;
  onMouseLeave: any;
}

export const MenuItem = ({
  name,
  id,
  section,
  onSetActive,
  onMouseEnter,
  onMouseLeave,
}: MenuItemProps) => (
  <Link
    to={`/${section}`}
    id={id}
    className="item"
    onClick={onSetActive}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    activeClassName="active"
  >
    <div>{name}</div>
  </Link>
);
