import React, { useEffect } from "react";

import { motion } from "framer-motion";

interface PropTypes {
  position: number;
  isVisible: boolean;
  isEffect: boolean;
}
export const AnimatedDot = ({ position, isVisible }: PropTypes) => {
  const [opacity, setOpacity] = React.useState(false);

  useEffect(() => {
    setTimeout(function() {
      setOpacity(true);
    }, 700);
  }, []);

  return (
    <motion.div
      style={{
        height: 6,
        width: 6,
        backgroundColor: "#de375c",
        borderRadius: "50%",
        opacity: 0,
        zIndex: 0,
        transition: "background 0.7s ease-in",
      }}
      initial={false}
      animate={{
        x: position,
        opacity: isVisible ? 1 : 0,
      }}
    />
  );
};
