import { NavigationState } from "./Navigation.types";
import { navItems } from "./Navigation.consts";

export const HIDE_NAV = "HIDE_NAV";
export const SET_TRANSPARENT = "SET_TRANSPARENT";
export const SET_STICKY_NAV = "SET_STICKY_NAV";
export const SET_IS_MENU_OPEN = "SET_IS_MENU_OPEN";
export const TOGGLE_SIMPLE_FORM_OPEN = "TOGGLE_SIMPLE_FORM_OPEN";
export const TOGGLE_ESTIMATE_OPEN = "TOGGLE_ESTIMATE_OPEN";
export const SET_PREV_SCROLL = "SET_PREV_SCROLL";
export const SET_DOT = "SET_DOT";

export const initialState = {
  prevScrollPosition: typeof window !== "undefined" ? window.pageYOffset : 0,
  visible: true,
  isMenuOpen: false,
  isEstimateOpen: false,
  isEstimateBackground: false,
  isSimpleFormOpen: false,
  isSimpleFormBackground: false,
  dot: {
    position: 45,
    isVisible: true,
    isEffect: false,
  },
  navItems: navItems,
  stickyNav: true,
  transparentBg: true,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case HIDE_NAV:
      return { ...state, visible: false };
    case SET_TRANSPARENT:
      return { ...state, transparentBg: action.payload };
    case SET_STICKY_NAV:
      return { ...state, stickyNav: action.payload };
    case SET_IS_MENU_OPEN:
      return { ...state, isMenuOpen: action.payload };
    case TOGGLE_SIMPLE_FORM_OPEN:
      return {
        ...state,
        isSimpleFormOpen: !state.isSimpleFormOpen,
        isSimpleFormBackground: !state.isSimpleFormBackground,
      };
    case TOGGLE_ESTIMATE_OPEN:
      return {
        ...state,
        isEstimateOpen: !state.isEstimateOpen,
        isEstimateBackground: !state.isEstimateBackground,
      };
    case SET_PREV_SCROLL:
      return {
        ...state,
        prevScrollPosition: action.payload.prevScrollPosition,
        visible: action.payload.visible,
      };
    case SET_DOT:
      return {
        ...state,
        dot: action.payload,
      };
    default:
      throw new Error();
  }
};
