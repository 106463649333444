import "./Forms.scss";

import { ErrorMessages, variantsContainer } from "./utils";
import { Form, Field, Formik } from "formik";
import { encode } from "./utils";
import AnimatedField from "./AnimatedField";
import { Checkbox } from "../widgets/Checkbox/Checkbox";
import React from "react";
import { motion } from "framer-motion";

const API_PATH = "/contact/redeem_coupon.php";

interface FormRequest {
  name: string;
  company: string;
  subject: string;
  description: string;
  contact: string;
  [key: string]: string;
}

interface FormValues {
  name: string;
  company: string;
  subject: string;
  description: string;
  contact: string;
  agree: boolean;
}
export interface SimpleFormProps {
  onClose(): void;
  isOpen: boolean;
}

export class SimpleForm extends React.Component<SimpleFormProps, any> {
  constructor(props: SimpleFormProps) {
    super(props);

    this.state = { completed: false, submitError: { value: false, text: "" } };
  }

  upload: any;

  submitForm = (
    form: FormRequest,
    setSubmitting: (submitting: boolean) => void
  ) => {
    setSubmitting(true);

    var requestBody: string[] = [];
    for (var property in form) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(form[property]);
      requestBody.push(encodedKey + "=" + encodedValue);
    }
    const requestQuery = requestBody.join("&");

    fetch(API_PATH, {
      body: requestQuery,
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      method: "POST",
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status.toString());
        setSubmitting(false);
        this.resetForm();
        this.setState({ completed: true });
      })
      .catch((err: any) => {
        setSubmitting(false);
        this.setState({
          submitError: {
            value: true,
            text: "Oops! Something went wrong when submitting the form!",
          },
        });
        setTimeout(() => {
          this.setState({
            submitError: {
              value: false,
              text: "",
            },
          });
        }, 5000);
      });
  };

  resetForm = () => {};

  closeForm = () => {
    this.props.onClose();
    setTimeout(() => {
      this.setState({ completed: false });
    }, 1000);
  };

  render() {
    const { submitError } = this.state;
    if (this.state.completed) {
      return (
        <div className="quick-estimate">
          <div className="thank-you">
            <h2>Thank you for your request. </h2>
            <h2>We will contact you soon!</h2>
            <button
              className="close"
              onClick={() => {
                this.closeForm();
              }}
            >
              Close
            </button>
          </div>
        </div>
      );
    }

    return (
      <motion.div
        animate={this.props.isOpen ? "open" : "closed"}
        variants={variantsContainer}
        className="quick-estimate"
      >
        <div className="container-items">
          <Formik
            initialValues={{
              name: "",
              company: "",
              subject: "",
              description: "",
              contact: "",
              agree: false,
            }}
            onSubmit={(values: FormValues, { setSubmitting }) => {
              setSubmitting(false);
              fetch("/", {
                method: "POST",
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
                body: encode({ "form-name": "contact-simple-form", ...values }),
              })
                .then((response) => {
                  if (!response.ok) throw new Error(response.status.toString());
                  setSubmitting(false);
                  this.resetForm();
                  this.setState({ completed: true });
                })
                .catch(() => {
                  this.setState({
                    submitError: {
                      value: true,
                      text:
                        "Oops! Something went wrong when submitting the form!",
                    },
                  });
                })
                .finally(() => setSubmitting(false));
            }}
            render={({
              setFieldValue,
              values,
              errors,
              touched,
              isSubmitting,
            }) => (
              <Form
                name="contact-simple-form"
                data-netlify={true}
                data-netlify-honeypot="bot-field"
              >
                <Field type="hidden" name="form-name" />
                <Field type="hidden" name="bot-field" />
                <div className="grid-container">
                  <div className="field-box">
                    {" "}
                    <AnimatedField isOpen={this.props.isOpen}>
                      <h1>Name (optional)</h1>
                    </AnimatedField>
                    <Field
                      className="name"
                      name="name"
                      id="name"
                      value={values.name || ""}
                      placeholder="Enter your name here..."
                      rows={5}
                      validate={(value: string) => {
                        return value.length > 1 || value.length === 0
                          ? null
                          : ErrorMessages.name;
                      }}
                    />
                    {errors.name && touched.name && (
                      <div className="error">{errors.name}</div>
                    )}
                  </div>
                  <div className="field-box">
                    <AnimatedField isOpen={this.props.isOpen}>
                      <h1>Company</h1>
                    </AnimatedField>
                    <Field
                      className="company"
                      name="company"
                      id="company"
                      value={values.company || ""}
                      placeholder="Enter your company here..."
                      rows={5}
                      validate={(value: string) => {
                        return value != null && value.length > 0
                          ? null
                          : ErrorMessages.company;
                      }}
                    />
                    {errors.company && touched.company && (
                      <div className="error">{errors.company}</div>
                    )}
                  </div>
                  <div className="field-box">
                    {" "}
                    <AnimatedField isOpen={this.props.isOpen}>
                      <h1>Subject (optional)</h1>
                    </AnimatedField>
                    <Field
                      className="subject"
                      name="subject"
                      id="subject"
                      value={values.subject || ""}
                      placeholder="Enter subject here..."
                      rows={5}
                      validate={(value: string) => {
                        return value.length > 1 || value.length === 0
                          ? null
                          : ErrorMessages.subject;
                      }}
                    />
                    {errors.subject && touched.subject && (
                      <div className="error">{errors.subject}</div>
                    )}
                  </div>
                  <div className="field-box">
                    <AnimatedField isOpen={this.props.isOpen}>
                      <h1>contact</h1>
                    </AnimatedField>
                    <div className="contact-row">
                      <Field
                        name="contact"
                        validate={(value: string) => {
                          const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                          const phoneNumberOnlyDigits = value.replace(
                            /[^0-9]/g,
                            ""
                          );
                          return (value != null && value.match(mailFormat)) ||
                            (phoneNumberOnlyDigits.match("[0-9]+") &&
                              phoneNumberOnlyDigits.length > 6)
                            ? null
                            : "Please provide contact.";
                        }}
                        placeholder="Your email or phone number..."
                      />
                      {errors.contact && touched.contact && (
                        <div className="error">{errors.contact}</div>
                      )}
                    </div>
                  </div>
                </div>
                <AnimatedField isOpen={this.props.isOpen}>
                  <h1>Description</h1>
                </AnimatedField>
                <Field
                  className="description"
                  component="textarea"
                  name="description"
                  id="description"
                  value={values.description || ""}
                  placeholder="Enter description here..."
                  rows={5}
                  validate={(value: string) => {
                    return value != null && value.length > 0
                      ? null
                      : ErrorMessages.projectDescription;
                  }}
                />
                {errors.description && touched.description && (
                  <div className="error">{errors.description}</div>
                )}
                <div className="separator" />
                <button
                  type="submit"
                  disabled={
                    isSubmitting ||
                    !(
                      errors.description == null &&
                      errors.contact == null &&
                      values.agree
                    )
                  }
                >
                  Submit request
                </button>
                <div className="conditions-row">
                  <Field
                    onChange={setFieldValue}
                    component={Checkbox}
                    name="agree"
                    id="agree2"
                    label="I agree to terms and conditions"
                  />
                  <a href="/privacy-policy" target={"_blank"}>Privacy</a>
                </div>
                {submitError.value && (
                  <div className="error">{submitError.text}</div>
                )}
              </Form>
            )}
          />
        </div>
      </motion.div>
    );
  }
}
