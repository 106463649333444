import React, { useState, useEffect } from "react";
import ScrollProgress from "scrollprogress";

const ProgressBar = ({ path }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const progressObserver = new ScrollProgress((x, y) => {
      setProgress(y * 100);
    });
    return () => {
      progressObserver.destroy();
    };
  }, [path]);

  const style = {
    backgroundColor: "#ff005a",
    height: "2px",
    position: "fixed",
    top: 0,
    bottom: 0,
    width: `${progress}%`
  };

  return <div className="progress-bar" style={style} />;
};

export default ProgressBar;
