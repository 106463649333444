import React from "react";
import "./Header.scss";

interface HeaderProps {
  title?: string;
  subTitle?: string;
  customElement?: object;
  subTitleNoBorderDesktop?: boolean;
}

export function Header({
  title,
  subTitle,
  customElement,
  subTitleNoBorderDesktop,
}: HeaderProps) {
  return (
    <div className="header-container">
      {!!title && <h1 className="header-title red-dot">{title}</h1>}
      {!!subTitle && (
        <h2
          className={`header-subtitle ${
            subTitleNoBorderDesktop ? "no-border-desktop" : ""
          }`}
        >
          {subTitle}
        </h2>
      )}
      {customElement && customElement}
    </div>
  );
}
