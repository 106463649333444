import React  from 'react';
import "./FootNotes.scss";

export function FootNotes(props){
    return (
        <div className={"foot-notes"} id={"sources"}>
            <h4 style={{color: "#de375c", fontSize: "22px"}}>Sources:</h4>
            {props.footnotes.map((footnote, i) => {
                return <p>
                    <em>
                        <span>{i + 1}.</span>
                        <a href={footnote.link} target={"_blank"}>{footnote.value}</a>
                    </em>
                </p>
            })}
        </div>
    )
}
