import React from "react";
import CouponIcon from "../../../assets/CouponIcon.svg";

export const NavigationRight = ({
  isEntre,
  handleSimpleFormToggle,
  handleEstimateToggle,
}) => (
  <div className="right">
    {isEntre ? (
      <div className="quick-estimate" onClick={handleSimpleFormToggle}>
        <div className="text">REDEEM YOUR COUPON</div>
        <div className="fab">
          <div>
            <CouponIcon alt="coupon icon" />
          </div>
        </div>
      </div>
    ) : (
      <div className="quick-estimate" onClick={handleEstimateToggle}>
        <div className="text">Quick estimate</div>
        <div className="fab">
          <div>✐</div>
        </div>
      </div>
    )}
  </div>
);
