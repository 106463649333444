export const variantsHamburgerMenu = {
  open: {
    opacity: 1,
    transition: {
      delay: 0.3,
    },
  },
  closed: {
    opacity: 0,
    transition: {
      delay: 0,
    },
  },
};

export const navItems = [
  {
    name: "About",
    section: "",
    dotPosition: 45,
    id: "about-link",
  },
  {
    name: "How we work",
    section: "how-we-work/",
    dotPosition: 172,
    id: "how-we-work-link",
  },
  {
    name: "Portfolio",
    section: "portfolio/",
    dotPosition: 317,
    id: "portfolio-link",
  },
  {
    name: "Blog",
    section: "blog/",
    dotPosition: 425,
    id: "blog-link",
  },
  {
    name: "Contact",
    section: "contact/",
    dotPosition: 525,
    id: "contact-link",
  },
];
