import React from "react";
import ReactMarkdown from "react-markdown";
import "./CustomStyleElement.scss";

export function CustomStyleElement(props) {
  return (
    <div
      style={props.style}
      className={`customStyleElement ${props.className ? props.className : ""}`}
    >
      <ReactMarkdown>{props.children}</ReactMarkdown>
    </div>
  );
}
