import "./Button.scss";

import React from "react";

interface IButtonProps {
  text: string;
  customClass?: string;
  [x: string]: any;
}

const Button = (props: IButtonProps) => {
  return (
    <button
      className={`button-red ${props.customClass ? props.customClass : ""}`}
      {...props}
    >
      {props.text}
    </button>
  );
};

export default Button;
