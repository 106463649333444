import "./Forms.scss";

import { Checkbox, CheckboxGroup } from "../widgets/Checkbox/Checkbox";
import { ErrorMessages, variantsContainer } from "./utils";
import { Form, Field, Formik } from "formik";
import { encode } from "./utils";
import AnimatedField from "./AnimatedField";
import React from "react";
import { motion } from "framer-motion";
import Button from "../widgets/Button/Button";

const API_PATH = "/forms/get_estimate.php";
interface FormRequest {
  platforms: string;
  description: string;
  need_uiux: string;
  budget_eur: string;
  contact: string;
  // file: Blob | string;
  [key: string]: string;
}

interface FormValues {
  platforms: string[];
  design: string[];
  noDesign: boolean;
  description: string;
  budget: string;
  noBudget: boolean;
  contact: string;
  agree: boolean;
  // file: File | null;
}
export interface QuickEstimateProps {
  onClose(): void;
  isOpen: boolean;
}

export class QuickEstimate extends React.Component<QuickEstimateProps, any> {
  constructor(props: QuickEstimateProps) {
    super(props);

    this.state = { completed: false, submitEror: { value: false, text: "" } };
  }

  upload: any;

  resetForm = () => {};

  closeForm = () => {
    this.props.onClose();
    setTimeout(() => {
      this.setState({ completed: false });
    }, 1000);
  };

  render() {
    const { submitEror } = this.state;
    if (this.state.completed) {
      return (
        <div className="quick-estimate">
          <div className="thank-you">
            <h2>Thank you for your request. </h2>
            <h2>We will contact you soon!</h2>
            <Button
              text="Close"
              className="close"
              onClick={() => {
                this.closeForm();
              }}
            />
          </div>
        </div>
      );
    }

    return (
      <motion.div
        animate={this.props.isOpen ? "open" : "closed"}
        variants={variantsContainer}
        className="quick-estimate"
      >
        <div className="container-items">
          <Formik
            initialValues={
              {
                platforms: [],
                design: [],
                noDesign: false,
                description: "",
                budget: "",
                noBudget: false,
                contact: "",
                agree: false,
              }
              // file: null
            }
            onSubmit={(values: FormValues, { setSubmitting }) => {
              setSubmitting(true);
              values.budget = values.noBudget
                ? "Not set"
                : values.budget.replace("€", "");

              fetch("/", {
                method: "POST",
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
                body: encode({ "form-name": "contact-estimate", ...values }),
              })
                .then((response) => {
                  if (!response.ok) throw new Error(response.status.toString());
                  setSubmitting(false);
                  this.resetForm();
                  this.setState({ completed: true });
                })
                .catch(() => {
                  this.setState({
                    submitEror: {
                      value: true,
                      text:
                        "Oops! Something went wrong when submitting the form!",
                    },
                  });
                })
                .finally(() => setSubmitting(false));
            }}
            render={({
              setFieldValue,
              setFieldTouched,
              values,
              errors,
              touched,
              isSubmitting,
            }) => {
              return (
              <Form
                name="contact-estimate"
                data-netlify={true}
                data-netlify-honeypot="bot-field"
              >
                <Field type="hidden" name="form-name" />
                <Field type="hidden" name="bot-field" />
                <AnimatedField isOpen={this.props.isOpen}>
                  <h1>Platform (optional)</h1>
                </AnimatedField>
                <CheckboxGroup
                  id="platforms"
                  value={values.platforms}
                  error={errors.platforms}
                  touched={touched.platforms}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                >
                  <Field
                    component={Checkbox}
                    name="platforms"
                    id="Android"
                    label="Android"
                  />
                  <Field
                    component={Checkbox}
                    name="platforms"
                    id="iOS"
                    label="iOS"
                  />

                  <Field
                    component={Checkbox}
                    name="platforms"
                    id="Web"
                    label="Web"
                  />
                  <Field
                    component={Checkbox}
                    name="platforms"
                    id="Backend"
                    label="Backend"
                  />
                  <Field
                    component={Checkbox}
                    name="platforms"
                    id="IoT"
                    label="IoT"
                  />
                  <Field
                    component={Checkbox}
                    name="platforms"
                    id="Other"
                    label="Other"
                  />
                </CheckboxGroup>
                <AnimatedField isOpen={this.props.isOpen}>
                  <h1>Description</h1>
                </AnimatedField>
                <Field
                  className="description"
                  component="textarea"
                  name="description"
                  id="description"
                  value={values.description || ""}
                  placeholder="Enter project description here..."
                  rows={5}
                  validate={(value: string) => {
                    return value != null && value.length > 0
                      ? null
                      : ErrorMessages.projectDescription;
                  }}
                />
                {errors.description && touched.description && (
                  <div className="error">{errors.description}</div>
                )}
                <AnimatedField isOpen={this.props.isOpen}>
                  <h1>Do you need design ?</h1>
                </AnimatedField>
                <div>
                  <CheckboxGroup
                    className="design"
                    id="design"
                    value={values.design}
                    error={errors.design}
                    touched={touched.design}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  >
                    <Field
                      component={Checkbox}
                      name="design"
                      id="Wireframes"
                      label="Wireframes"
                    />

                    <Field
                      component={Checkbox}
                      name="design"
                      id="UserInterface"
                      label="User Interface"
                    />

                    <Field
                      onChange={setFieldValue}
                      component={Checkbox}
                      name="design"
                      id="noDesign"
                      label="No"
                    />
                  </CheckboxGroup>
                </div>
                {values.design.length === 0 && touched.design && (
                  <div className="error">{ErrorMessages.atLeastOne}</div>
                )}
                <AnimatedField isOpen={this.props.isOpen}>
                  <h1>Your budget</h1>
                </AnimatedField>
                <div className="budget">
                  <div>
                    <div>
                      <Field
                        className="budget"
                        name="budget"
                        id="budget"
                        placeholder={
                          values.noBudget ? "Not set" : "Enter here..."
                        }
                        value={values.noBudget ? "" : values.budget}
                        onChange={(event: {
                          currentTarget: { value: string };
                        }) => {
                          let newValue;
                          if (event.currentTarget.value.includes("€")) {
                            newValue = event.currentTarget.value;
                          } else {
                            newValue = event.currentTarget.value.slice(0, -1);
                          }
                          setFieldValue(
                            "budget",
                            newValue.replace("€", "") + "€"
                          );
                        }}
                        disabled={values.noBudget}
                        validate={(value: string) => {
                          return /^\d*€{1}$/.test(value) || values.noBudget
                            ? null
                            : "Please provide amount.";
                        }}
                        autoComplete="off"
                        onFocus={() => {
                          if (values.budget === "")
                            setFieldValue("budget", "€");
                          touched.budget = true;
                        }}
                        onBlur={() => {
                          if (values.budget === "€")
                            setFieldValue("budget", "");
                        }}
                      />
                    </div>
                    {errors.budget && (touched.budget || touched.noBudget) && (
                      <div className="error">{errors.budget}</div>
                    )}
                  </div>
                  <div className="noBudget">
                    <Field
                      component={Checkbox}
                      name="noBudget"
                      id="noBudget"
                      label="Not set"
                    />
                  </div>
                </div>
                <AnimatedField isOpen={this.props.isOpen}>
                  <h1>contact</h1>
                </AnimatedField>
                <div className="contact-row">
                  <div>
                    <Field
                      name="contact"
                      validate={(value: string) => {
                        const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                        const phoneNumberOnlyDigits = value.replace(
                          /[^0-9]/g,
                          ""
                        );
                        return (value != null && value.match(mailFormat)) ||
                          (phoneNumberOnlyDigits.match("[0-9]+") &&
                            phoneNumberOnlyDigits.length > 6)
                          ? null
                          : "Please provide contact.";
                      }}
                      placeholder="Your email or phone number..."
                    />
                    {errors.contact && touched.contact && (
                      <div className="error">{errors.contact}</div>
                    )}
                  </div>
                  {/* <div>
                    <input
                      ref={ref => (this.upload = ref)}
                      id="file"
                      name="file"
                      type="file"
                      onChange={event => {
                        event.currentTarget.files &&
                          setFieldValue("file", event.currentTarget.files[0]);
                      }}
                    />
                    <button
                      className="upload-button"
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        values.file === null && this.upload.click();
                      }}
                    >
                      <div>
                        {values.file ? values.file.name : "attach a file"}
                      </div>
                    </button>
                    {values.file !== null && (
                      <div
                        className="cancel-upload"
                        onClick={() => {
                          setFieldValue("file", null);
                        }}
                      >
                        <div>
                          <img src={xShape} alt="" />
                        </div>
                      </div>
                    )}
                    {values.file && values.file.size > 3000000 && (
                      <div className="error error-padding">
                        {ErrorMessages.fileSize}
                      </div>
                    )}
                  </div> */}
                </div>
                <div className="separator" />
                <button
                  type="submit"
                  disabled={
                    isSubmitting ||
                    !(
                      (values.budget || values.noBudget) &&
                      errors.description == null &&
                      errors.contact == null &&
                      values.agree &&
                      values.design.length > 0
                    ) // (values.file !== null && values.file.size) > 3000000 ||
                  }
                >
                  Submit request
                </button>
                <div className="conditions-row">
                  <Field
                    onChange={setFieldValue}
                    component={Checkbox}
                    name="agree"
                    id="agree"
                    label="I agree to terms and conditions"
                  />
                  <a href="/privacy-policy" target={"_blank"}>Privacy</a>
                </div>
                {submitEror.value && (
                  <div className="error">{submitEror.text}</div>
                )}
              </Form>
            )}}
          />
        </div>
      </motion.div>
    );
  }
}
