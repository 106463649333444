import { BlogVideo } from "../../../../src/components/Blog/postElements/BlogVideo";
import { BlogTable } from "../../../../src/components/Blog/postElements/BlogTable";
import { ImageMaxWidth } from "../../../../src/components/Blog/postElements/ImageMaxWidth";
import { BannerMobileApp, BannerMobileWallet } from "../../../../src/components/Blog/postElements/BannerMobile";
import { PostIntro } from "../../../../src/components/Blog/postElements/PostIntro";
import * as React from 'react';
export default {
  BlogVideo,
  BlogTable,
  ImageMaxWidth,
  BannerMobileApp,
  BannerMobileWallet,
  PostIntro,
  React
};